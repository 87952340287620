export const truncate = (str, count = 140, ending = '...') => {
  if (str.length > count) {
    return str.substring(0, count - ending.length) + ending
  } else {
    return str
  }
}

export const joinByAnd = a => {
  return [a.slice(0, -1).join(', '), a.slice(-1)[0]].join(a.length < 2 ? '' : ' and ')
}

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const basicPluralize = (word, count) => {
  return count === 1 ? word : word + 's'
}

export function formatPhoneNumbers(phoneNumber) {
  let phoneNumberArr
  const type = typeof phoneNumber
  if (type !== 'string' && type !== 'number') {
    return phoneNumber
  } else if (type === 'number') {
    phoneNumber = phoneNumber.toString()
  }
  if (phoneNumber.length !== 10) {
    return phoneNumber
  }

  phoneNumberArr = phoneNumber.split('')
  phoneNumberArr.splice(3, 0, '.')
  phoneNumberArr.splice(7, 0, '.')

  return phoneNumberArr.join('')
}

export function ordinalSuffix(i) {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export function parameterize(s) {
  return s
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '')
}

export const capitalize = str => {
  return str[0].toUpperCase() + str.toLowerCase().substring(1)
}

export const camelCaseToWords = str => {
  return str.replace(/[a-z][A-Z]([a-zA-Z]|\W|\b)/g, x => `${x[0]} ${x.slice(1)}`)
}

export const snakeToCamel = s => {
  return s.replace(/((_|-)\w)/g, function (c) {
    return c[1].toUpperCase()
  })
}

export const camelToSnake = s => {
  return s.replace(/([A-Z])/g, function (c) {
    return '_' + c.toLowerCase()
  })
}
