
import { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react';
import { HbAnalyticsClass } from './AnalyticClasses'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (this.props.isInnerErrorBoundary) {
        scope.setExtra("inInnerErrorBoundary", true)
        scope.setLevel('fatal');
      } else {
        scope.setExtra("inOuterErrorBoundary", true)
        scope.setLevel('fatal');
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
          <div className="text-center mx-4">
            <h3 className="mt-4 mb-0 text-center">Sorry something has gone wrong.</h3>
            <h6 className="mt-4 mb-1 text-center">
              There seems to have been an error.
            </h6>
            <h6 className="text-center">
              The details have been reported to our team. Please&nbsp;
              <a href={this.props.isAgentErrorBoundary ? "mailto:support@realscout.com?subject=Issue using agent experience" : "mailto:support@realscout.com?subject=Issue using homebuyer experience"}>
                contact us
              </a>
              &nbsp;if you continue to experience this issue.
            </h6>
            <a href="#"
               onClick={() => {window.location.reload()}}
               className={`btn btn-primary ${this.props.isAgentErrorBoundary ? '' : HbAnalyticsClass('refresh', this.props.isInnerErrorBoundary ? 'inner-error-boundary' : 'outer-error-boundary')}`}>
              Refresh
            </a>
          </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary)
