import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const RsPageLoading = () => (
  <Box display="flex" alignItems="center" justifyContent="center" py={6} style={{ minHeight: '100%' }}>
    <CircularProgress />
  </Box>
)

export default RsPageLoading
