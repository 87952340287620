import { Chip, useTheme } from '@mui/material'

const RsBadge = ({ color, sx, ...restProps }) => {
  // Can figure out how to make this work with the sx
  // prop in the future if necessary
  if (sx) {
    console.warn('RsBadge overrides sx prop, sx prop does not work')
  }

  const theme = useTheme()

  const styles = {
    fontSize: '12px',
    borderRadius: 0.5,
    fontWeight: 'bold',
    maxWidth: 'fit-content',
  }

  switch (color) {
    case 'primary':
      Object.assign(styles, {
        background: theme.colors.primary.lighter,
        color: theme.colors.primary.main,
      })
      break
    case 'success':
      Object.assign(styles, {
        background: theme.colors.success.lighter,
        color: theme.colors.success.main,
      })
      break
    case 'info':
      Object.assign(styles, {
        background: theme.colors.info.lighter,
        color: theme.colors.info.main,
      })
      break
    case 'warning':
      Object.assign(styles, {
        background: theme.colors.warning.lighter,
        color: theme.colors.warning.main,
      })
      break
    case 'error':
      Object.assign(styles, {
        background: theme.colors.error.lighter,
        color: theme.colors.error.main,
      })
      break
    default:
      Object.assign(styles, {
        background: theme.colors.alpha.black[10],
        color: theme.colors.palette.text.main,
      })
  }

  return <Chip sx={{ ...styles }} {...restProps} />
}

export default RsBadge
